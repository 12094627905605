<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-alert
        type="info"
        color="lime darken-2"
        :value="true"
        class="mb-4"
        outline
      >
        <strong> The LEAD (Leadership, Educate & Development) Program </strong> is to develop and build
        knowledge and skills of the XOX Dealer/Partners and enable them to build, grow their business
        and income.
        <td>&nbsp;</td>
        In addition to being issued a personal certificate (where applicable), each dealer/partner that
        have attended and <v-spacer /> completed the class will earn credit hours and recognition in the OneSys (badge).
        <td>&nbsp;</td>
        All certificates/credit hours and badge will only be earned upon each dealer or partner who completed
        the feedback <v-spacer /> form at the end of each class.  The feedback form serves as a confirmation that the
        dealer attended the class from <v-spacer /> start to finish.
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>Credit Hours</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        hide-actions
        expand
        class="elevation-1"
        disable-initial-sort
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.subjectName }}
            </td>
            <td class="text-xs-center">
              {{ props.item.certificate }}
            </td>
            <td class="text-xs-center">
              {{ props.item.badge }}
            </td>
            <td class="text-xs-center">
              {{ props.item.creditHours }}
            </td>
            <td class="text-xs-center">
              <v-icon
                v-if="props.item.attend === 'Y'"
                color="success"
              >
                mdi-check
              </v-icon>
              <template
                v-else-if="props.item.attend === ''"
              />
              <v-icon
                v-else
                color="error"
              >
                mdi-close
              </v-icon>
            </td>
          </tr>
        </template>
        <template
          v-if="count"
          #footer
        >
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="text-xs-left">
              Number of subjects attended:
            </td>
            <td class="text-xs-center primary--text">
              {{ count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="text-xs-left">
              Total credit hours earned:
            </td>
            <td class="text-xs-center primary white--text">
              {{ total }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'

const pageTitle = 'Credit Hours'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "Panel" */ '@/components/Panel'),
  },
  data () {
    return {
      title: pageTitle,
      items: [],
      entrys: [],
      loading: false,
      breadcrumbs: [
        {
          text: 'Lead Program', disabled: false, to: '/lead_menu',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      headers: [
        {
          text: 'Subject Name',
          align: 'left',
          sortable: true,
          value: 'subjectName',
        },
        {
          text: 'Certificate',
          align: 'left',
          sortable: true,
          value: 'certificate',
        },
        {
          text: 'Badge',
          align: 'left',
          sortable: true,
          value: 'badge',
        },
        {
          text: 'Credit Hours',
          align: 'left',
          sortable: true,
          value: 'creditHours',
        },
        {
          text: 'Attended Class',
          align: 'left',
          sortable: true,
          value: 'attended',
        },
      ],
      total: 0,
      count: 0,
    }
  },

  mounted: function () {
    this.getLeadClass()
  },

  methods: {
    getLeadClass () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getLeadClassData.php', params)
        .then((response) => {
          this.items = response.data.class
          this.total = response.data.total
          this.count = response.data.count
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
